@import "variables";

@mixin global-reset {
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote {
    &:before, &:after {
      content: "";
      content: none;
    }
  }

  q {
    &:before, &:after {
      content: "";
      content: none;
    }
  }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;

        background: transparent;

        /* inherit font & color from ancestor */
        color: inherit;
        font: inherit;

        /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
        line-height: normal;

        /* Corrects font smoothing for webkit */
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;

        /* Corrects inability to style clickable `input` types in iOS */
        -webkit-appearance: none;
    }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
 
  del {
  	text-decoration: none;
  }

  * {
	  box-sizing: border-box;
	
	  &:focus {
	    outline: none !important;
	  }
	
	  &::-moz-focus-inner {
	    border: 0 !important;
	  }
  }


}

@mixin font-face($family, $dir, $file-name, $style: normal, $weight: normal, $svg-id: null, $formats: (eot: embedded-opentype, woff2: woff2, woff: woff, ttf: truetype, otf: opentype, svg: svg)) {
  $src: ();

  @each $ext, $format in $formats {
    @if $ext == "eot" {
      $ext: "#{$ext}#iefix";
    }
    @else if $ext == "svg" and $svg-id != null {
      $ext: "#{$ext}##{$svg-id}";
    }

    $src: append($src, url("#{$dir}#{$file-name}.#{$ext}") format("#{$format}"), comma);
  }

  @at-root {
    @font-face {
      font-family: "#{$family}";

      @if map-has-key($formats, eot) {
        src: url("#{$dir}#{$file-name}.eot");
      }

      src: $src;
      font-style: $style;
      font-weight: $weight;
    }
  }
}

@mixin clearfix {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// convert pixel to em upon browser context, normally 16px
@function rem($pixels, $context: $browser-context) {
	$rem_size: $pixels / $context;
	@return $rem_size * 1rem;
}

// make font-size in px and rem size
@mixin font-size($size) {
	font-size: #{$size}px;
	font-size: rem($size);
}

@function empx($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}
//@function empercent($pixels, $context: $browser-context) {
//    @return #{$pixels/$context*100%};
//}



