$font-default: 'SBBWeb-Roman';
$font-bold: 'SBBWeb-Bold';
$font-light: 'SBBWeb-Light';
$font-thin: 'SBBWeb-Thin';

$browser-context: 16; // basic font size on body, used for px to em calculations

/*
    Use bare values without px because used in function to calc em's
 */

/* breakpoints */

$screen-s-px: 400;
$screen-m-px: 768;
$screen-l-px: 1025;


$header-h-mobile-px: 48;
$header-h-tablet-px: 70;
$header-h-screen-px: 70;


$nav-padding-mobile-px: 25;
$nav-padding-screen-px: 40;


// panel_w_mobile not defined as _px -> use 100%
$detail-w-tablet-px: 300;
$detail-w-screen-px: 420;

// Default one Title eg. Line Detail
$detail-header-h-mobile-px: $header-h-mobile-px + 25;
$detail-header-h-tablet-px: $header-h-tablet-px;
$detail-header-h-screen-px: $header-h-screen-px;

// Double Title Origin & Destination eg. Station Detail
$detail-header-station-h-mobile-px: 2 * $header-h-mobile-px + 25;
$detail-header-station-h-tablet-px: 2 * $header-h-tablet-px;
$detail-header-station-h-screen-px: 2 * $header-h-screen-px;

$detail-padding-left-x: 12;

$line-label-w-px: 40;

$font-size-panel-title: 175%;

$line-h-factor-small: 1.2;
$line-h-factor-medium: 1.4;
$line-h-factor-large: 1.7;

$color-red-sbb: #EB0000;// #c60018;
$color-red125-sbb: #C60018;

$color-bg-grey: #E5E5E5;
$color-bg-lightgrey: #f2f2f2;
$color-bg-mediumgrey: #666;
$color-bg-darkgrey: #666666;

$color-text-lightgrey: #767676;
$color-text-grey: #666666;
$color-border-grey: #d9d9d9;
$color-border-lightgrey: #f2f2f2;

$color-header-burger: #444;
$color-header-icons: #767676;

$color-input-border: #b7b7b7;
$color-input-border-active: #666;

$input-border-size-px: 2;
$input-border-radius-px: 2;

$autocomplete-w-s-px: 230;
$autocomplete-w-m-px: 250;

$autocomplete-close-px: 30;

$autocomplete-padding-px: 15;

$ani-sec-burger: 0.25s;
