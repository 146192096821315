// default styles for default react app showcase
@import "utils";

.detail-content{
    $next-departures-transition-duration: 0.5s;


    h1{
        padding-left: empx($detail-padding-left-x);
        padding-right: empx($detail-padding-left-x);
        margin-top: empx(20);
        margin-bottom: empx(30);
        @media (min-width: empx($screen-l-px)) {
            margin-top: empx(20);
        }
        .text{
            font-family: $font-light;
            font-size: empx(22);
            line-height: $line-h-factor-small;

            @media (min-width: empx($screen-l-px)) {
                font-size: empx(26);
            }
        }
        .origin,
        .arrow,
        .destination{
            padding-top: empx(10);
            font-size: empx(22);
            font-family: $font-light;
            line-height: $line-h-factor-small;

            @media (min-width: empx($screen-l-px)) {
                font-size: empx(26);
            }
        }
        .arrow{
            padding-left: empx(5);
            padding-right: empx(5);
        }

        &.message{
            margin-top: empx(50);
            margin-bottom: empx(30);
            text-align: center;

            @media (min-width: empx($screen-l-px)) {
                margin-top: empx(70);
                margin-bottom: empx(50);
            }

            .text{

                font-family: $font-light;
                font-size: empx(16);

                @media (min-width: empx($screen-l-px)) {
                    font-size: empx(20);
                }
            }
        }
    }

    h2{
        margin-top: empx(30);
        .text{
            font-family: $font-light;
            font-size: empx(18);
            @media (min-width: empx($screen-l-px)) {
                font-size: empx(20);
            }
        }
        padding-left: empx($detail-padding-left-x);
    }

    .detail-line-basetacts{
        margin-top: empx(25);
    }

    .link-button-wrapper{
        height: empx(30);
        position: relative;
        transition: opacity 0s $next-departures-transition-duration;
        .link-button{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: empx(13);
        }
    }

    .content-box{
        width: 80%;
    }

    .next-departures{
        margin-top: empx(5);

        .loader{

            //background-color: $color-bg-grey;
            height: empx(126);

            display: flex;
            justify-content: center;
            //align-items: center;
        }

        .expandable-area{
            transition: max-height $next-departures-transition-duration;
            max-height: empx(96);
            overflow-y: hidden;
        }

        &.next-departures-expanded{

            .expandable-area{
                max-height: empx(700);
            }

            .link-button-wrapper.show-more-next-departures{
                opacity: 0;
                visibility: hidden;
                transition: opacity 0s 0s;
            }
        }

        .divtable-next-departures{
            // lives inside expandable-area
            width: 100%;

            .text.warning-delayed{
                color: $color-red125-sbb;
                @media (-ms-high-contrast: active) {
                    font-family: $font-bold;
                }
            }

            .divtable-row{
                display: flex;
                height: empx(24);
                .divtable-columnheader{
                    border-bottom: 1px solid $color-border-grey;
                    padding-top: empx(6);

                    .text{
                        font-size: empx(12);
                    }
                }
                .divtable-cell{
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $color-border-grey;
                    .text{
                        font-size: empx(13);
                    }
                }

                // header
                .divtable-columnheader:nth-child(1){
                    flex: 0 0 empx($detail-padding-left-x + $line-label-w-px);
                    flex-basis: empx($detail-padding-left-x + $line-label-w-px);
                    padding-left: empx($detail-padding-left-x);
                    //background-color: yellow;
                }
                .divtable-columnheader:nth-child(2){
                    flex: 0 0 13%;
                    padding-left: empx(10);
                    //background-color: mediumvioletred;
                }
                .divtable-columnheader:nth-child(3){
                    flex: 1 1 61%;
                    padding-left: empx(10);
                    //background-color: purple;
                }
                .divtable-columnheader:nth-child(4){
                    flex: 0 0 13%;
                    padding-left: empx(10);
                    //background-color: blue;
                }

                // content
                .divtable-cell:nth-child(1){
                    flex: 0 0 empx($detail-padding-left-x + $line-label-w-px);
                    flex-basis: empx($detail-padding-left-x + $line-label-w-px);
                    padding-left: empx($detail-padding-left-x);
                    .svg-wrapper{
                        //flex: 1 1 100%;
                        flex-basis: 100%;
                        //max-height: 26%;
                        img{
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }

                    //background-color: #fffbc0;
                }
                .divtable-cell:nth-child(2){
                    flex: 0 0 13%;
                    padding-left: empx(10);
                    //background-color: #d695be;
                }
                .divtable-cell:nth-child(3){
                    flex: 1 1 61%;
                    padding-left: empx(10);
                    //background-color: #bfafda;
                }
                .divtable-cell:nth-child(4){
                    flex: 0 0 13%;
                    padding-left: empx(10);
                    //background-color: #dae3ff;
                }

                &.empty-row .divtable-cell{
                    flex: 0 0 100%;
                    justify-content: center;
                }
            }
        }

    }




    //////////////////////////////////
    // Grundtakt

    .direction-title{
        display: flex;
        margin-top: empx(20);
        padding-bottom: empx(5);
        h3{
            flex: 0 0 70%;
            padding-left: empx($detail-padding-left-x);
            display: flex;
            align-items: center;
            .text{
                font-size: empx(16);
                //@media (min-width: empx($screen-m-px)){
                //    font-size: empx(15);
                //}
                font-family: $font-light;
            }
        }
        .label-departure-minute{
            flex: 0 0 30%;
            display: flex;
            justify-content: center;
            padding-right: empx(8);
            align-items: flex-end;
            .text{
                font-size: empx(13);
                text-align: center;
                //@media (min-width: empx($screen-m-px)){
                //    font-size: empx(15);
                //}
            }
        }
    }

    .basetact{
        transition:  background-color 0.5s;
        background-color: $color-bg-grey;
        margin-bottom: 1px;

        .basetact-header{
            position: relative;
            min-height: empx(60);
            overflow-y: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .basetact-labels{
                flex: 1 1 65%;
                padding-left: empx($detail-padding-left-x);
                padding-top: empx(10);
                padding-bottom: empx(10);
                .line-labels{
                    display: flex;
                    padding-bottom: empx(3);
                    div{
                        flex: 0 0 empx($line-label-w-px + 1);
                        margin-right: empx(1);
                    }
                }
                .basetact-label{
                    display: flex;
                    align-items: center;

                    .text{
                        font-size: empx(14);
                    }
                }


            }
            .infomessage-icon{
                flex: 0 0 10%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .departure-minute{
                flex: 0 1 12%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .text{
                    font-size: empx(14);
                }
            }
            .toggle-basetact-detail-visual{

                flex: 0 0 13%;
                display: flex;
                justify-content: center;
                align-items: center;

                .plus-icon{
                    flex: 0 0 empx(25);
                    height: empx(25);
                }
            }
            .button-toggle-basetact-detail{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                cursor: pointer;

                @media screen and (prefers-contrast: high){
                    width: 10px;
                }
            }

            .minus-icon{
                display: none;
            }
        }

        .basetact-body{

            max-height: 0;
            height: auto;
            overflow-y: hidden;
            transition: max-height .5s;

            .infomessages{
                margin-top: empx(30);
                margin-bottom: empx(20);

                li{
                    text-align: center;
                    padding: 0 empx(10);
                    &:not(:last-child){
                        margin-bottom: empx(6);
                    }

                    .text{
                        font-size: empx(13);
                    }
                    .warning{
                        color: $color-red-sbb;
                    }
                }
            }

            .basetact-stops{

                $li-h-px: 50;
                $bullet-w-px: 12;

                padding-bottom: empx(60);

                li{
                    display: flex;
                    height: empx($li-h-px);

                    //border-bottom: 1px solid cyan;

                    .text{
                        font-size: empx(13);

                        &.is-origin{
                            font-family: $font-bold;
                        }

                        &.soft{
                            font-family: $font-light;
                            color: #666;
                        }
                    }

                    .time{
                        flex: 0 0 45%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .time-group{
                            flex: 0 0 100%;

                            .arrival,
                            .departure{
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                height: empx(15);

                                > .visuallyhidden{
                                    // fixes stupid large empty space at detail scroll end
                                    position: inherit !important;
                                }
                            }

                            .arrival .text{
                                font-family: $font-light;
                                color: #666;
                            }
                        }

                    }
                    .visual{
                        flex: 0 0 10%;
                        //background-color: yellow;
                        position: relative;
                        
                        &:before {
                            content: "";
                            position: absolute;
                            top: empx($li-h-px/2);
                            bottom: empx(-$li-h-px/2 - 2);
                            left: 50%;
                            margin-left: empx(-1);
                            width: empx(2);
                            border-left: empx(2) solid #000;
                        }

                        &:after{
                            content: "";
                            position: absolute;
                            top: empx($li-h-px/2 - 2);
                            left: 50%;
                            margin-left: empx(-4);
                            width: empx(4);
                            height: empx(4);
                            background: #fff;
                            border: empx(2) solid #000;
                            border-radius: 50%;
                            z-index: 1;
                        }

                        &.visual-first-stop-inactive{

                            &:before {
                                border-left: empx(2) dotted #999;
                            }

                            &:after{
                                content: "";
                                position: absolute;
                                top: empx($li-h-px/2 - $bullet-w-px/2);
                                left: 50%;
                                margin-left: empx(-$bullet-w-px/2);
                                width: empx($bullet-w-px);
                                height: empx($bullet-w-px);
                                background: #999;
                                border: none;
                                border-radius: 50%;
                                z-index: 1;
                            }
                        }

                        &.visual-full-bullet{

                            &:after{
                                content: "";
                                position: absolute;
                                top: empx($li-h-px/2 - $bullet-w-px/2);
                                left: 50%;
                                margin-left: empx(-$bullet-w-px/2);
                                width: empx($bullet-w-px);
                                height: empx($bullet-w-px);
                                background: #000;
                                border: none;
                                border-radius: 50%;
                                z-index: 1;
                            }
                        }
                        
                        &.visual-last-stop{
                            &:before {
                                border: none;
                            }
                            &:after{
                                content: "";
                                position: absolute;
                                top: empx($li-h-px/2 - $bullet-w-px/2);
                                left: 50%;
                                margin-left: empx(-$bullet-w-px/2);
                                width: empx($bullet-w-px);
                                height: empx($bullet-w-px);
                                background: #000;
                                border: none;
                                border-radius: 50%;
                                z-index: 1;
                            }
                        }

                    }

                    .station-label{
                        flex: 0 0 45%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-right: empx(10);

                        &.inactive{
                            opacity: 0.5;
                        }

                    }

                    &.infomessage-warning{
                        .visual{
                            &:after{
                                border-color: $color-red-sbb;
                            }
                            &.visual-full-bullet:after,
                            &.visual-last-stop:after{
                                background: $color-red-sbb;
                            }
                            &.visual-first-stop-inactive:after{
                                background: #EB989B;
                            }
                        }
                        .time .arrival .text,
                        .time .departure .text,
                        .station-label{
                            color: $color-red-sbb;
                        }
                        .station-label .soft{
                            color: #cf8689;
                        }
                    }
                }
            }

        }

        &.basetact-detail-open{
            //background-color: white;

            .basetact-body{
                max-height: empx(1500);
                transition: max-height 1.5s;
            }
            .plus-icon{
                display: none;
            }
            .minus-icon{
                display: block;
            }
        }
    }

    .content-disclaimer{
        margin-top: empx(15);
        color: $color-text-grey;
        text-align: center;
        .text{
            font-size: empx(14);
            font-family: $font-light;
        }
    }
}
